import React from "react";
import post_amicoo1 from './images/Post-amicoo1.png';
import connection10 from './images/connection10.png';
import "./section27.css";
export default function Section27() {
  return (
    <div className="onesection-27">
      <div className="col-md-12 text-center">
        <h3 className="lh-4 my-margin-t">
          <span className="title3-out">
            <span className="title3">
              <span className="fw900 ff2">What is Holding You Back?</span>
            </span>
          </span>
        </h3>
      </div>
      <div className="col-md-12">
        <img
          src={connection10} alt="connection10"
          className="img-fluid mx-auto d-block"
        />
      </div>
      <div className="one-section one-section-27 pb-0 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="guarantee-section">
                <div className="row align-items-center padng ">
                  <div className="col-md-6 col-sm-12">
                    <div className="margin-r-l120">
                      <h1 className="lh-4 text-white ff2"><span className="fw600">Don't Let <span className="fw900">Customer Engagement</span> Slip Away</span>
                      </h1>
                      <p className="fw400 fs-20 lh-4 margint10 text-off-white">If you find yourself tirelessly chasing customers without inspiring them to make purchases, you're not alone in facing this challenge. However, you have a choice to make. Will you continue with your current strategy, which may be wasting your time? Or will you join the trend of maximizing profits with less effort?</p>
                      <p className="fw400 fs-20 lh-4 text-white">Embrace the approach of successful brands and make a substantial impact on the market. It's time to elevate your game and achieve remarkable results.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="margin-r-l120 my-mt-5-max767">
                      <img src={post_amicoo1} alt="Post" className="img-fluid mx-auto d-block max-w500"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="max-w900 text-center mt-1 mb-1 mx-auto">
              <h3 className="fw400 mt-5 text-center ff2"><span className="title3-out"><span className="title3"><span className="fw900">Unlock These Benefits with Just One Click</span></span></span></h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <img
          src={connection10} alt="connection10"
          className="img-fluid mx-auto d-block"
        />
      </div>
    </div>
  );
}

import React from 'react'
import arrow from "./images/arrow.png";
import paytm from "./images/paytm.png";
import bg1 from './images/bg2-1.png';
import bg2 from './images/bg2-2.png';
import bg_SmartMails from './images/bg_smartmails.png';

import './section2.css';

export default function Section2() {
  return (
    <>
      <div className="one-section one-section-2 pb-0" style={{ backgroundImage: `url(${bg1})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 section2-box max-w950 m-auto mb-5">
            <div className="section2-box-inn">
              <div className="row justify-content-around m-0">
                <h3 className="fw500 mt-2 text-center ff2">
                  <em>Get Started with <span className="fw900 text-dark-blue">SmartMails</span></em></h3>
                <div className="col-md-12 text-center mt-4">
                  <div className="mybutton">
                    <a href="#pricing" className="text-center fw400 text-uppercase"> <span className="mybutton-btn">
                      Sign Up for Free
                      <img
                          src={arrow} alt="arrow"
                          className="img-fluid center-block btn-arrow"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <img
                      src={paytm} alt="payment"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className='container one-section-2-middle'>
          <div className="row">
            <div className="col-md-9 text-center mt-5 mx-auto">
              <h1 className="fw900 mt-1 text-blue ff2"><em>Attract Your <span className="fw900 text-dark-blue">Audience</span> like Never Before</em></h1>
              <p className="fw400 lh-4 fs-20">With over <b>4.26 billion</b> email users worldwide, overlooking this vast market would be a mistake you can't afford. Don't feel trapped when an immense marketplace with limitless potential is right before your eyes. Attract more customers and generate a higher return on investment in your own unique style. SmartMails presents mesmerizing features that create a win-win situation for your brand.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="one-section one-section-2-lower" style={{ backgroundImage: `url(${bg2})` }}>
        <div className="container">
          <div className="row section2-mt">
            <div className="col-md-6 align-self-center">
              <img
                src={bg_SmartMails} alt="wondering"
                className="img-fluid mx-auto d-block"
              />
            </div>
            <div className="col-md-5 align-self-center mt-5">
              <h1 className="fw900 ff2">Unlock All Your Brand Needs with <span className="fw700 text-dark-blue">SmartMails!</span></h1>
              <p className="fw400 text-black mt-3 fs-20">SmartMails is an exceptionally intelligent and creative automation tool that empowers you to automate your marketing funnel. We provide you with a cutting-edge approach to reaching users' mailboxes with a high deliverability rate.</p>
              <p className="fw400 text-black mt-3 fs-20">Sending an email is not just about sending it; it's about ensuring it lands directly in the inbox. SmartMails comprehends how spam filters function, allowing you to send each email for maximum open and click-through rates.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import React from 'react'
import Iceberg from './images/dive.png';
import bg4 from './images/bg4.jpg';
import './section4.css';

export default function Section4() {
	return (
		<div className="one-section one-section-4" style={{backgroundImage: `url(${bg4})`}}>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-4 align-self-center ">
						<img
							src={Iceberg} alt="pocket"
							className="img-fluid mx-auto d-block pocket"
						/>
					</div>
					<div className="col-md-6 m-auto align-self-center">
						<h1 className="text-white fw900 ff2">There's More to Discover Beyond the Surface</h1>
						<p className="fw400 text-white fs-20">Explore an abundance of valuable features that will ensure the success of your email campaigns, ultimately contributing to the prosperity of your brand.</p>
					</div>
				</div>
			</div>
		</div>
	)
}
import React from 'react';
import Header from '../Header/Header';
import bg from "./images/bg-1.jpg";
import banner from "./images/home-banner.png";
import './section1.css';

export default function Section1() {
  return (
    <div className="one-section one-section-1 banner" style={{ backgroundImage: `url(${bg})` }}>
      <Header />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 text-center">
            <p className="title121 fs-22 mt-3 ff2 fw700">Feeling drained after investing significant resources into marketing channels that have yielded no results?</p>
            <div className="d-block">
              <h1 className="text-white ff2">Introducing SmartMails</h1>
              <div className="bg-line"></div>
            </div>
            <h1 className="text-white fs-60 ff2">The Email Marketing Hub!</h1>
            <h2 className="text-white mt-4 para1">Are you excited to boost your income with minimal effort? The significance of email marketing cannot be underestimated. Why, you ask? Because email marketing automation delivers an impressive <span className="fw700 text-logo-color">$42 return on investment</span>  for every $1 spent</h2>
              <img
                src={banner} alt="banner"
                className="img-blk"
              />
            <p className="text-white mt-4 max-w950 m-auto mb-4 fs-20 para2">If your goal is to enhance your <span className="text-logo-color">revenues, engagement, and communication</span> while safeguarding your valuable data, you've come to the right place.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 mx-auto text-center">
            <div className="title2">
              <p className="text-white fs-20 max-w1100 mx-auto pera3 text-blk mb-5">With SmartMails, you have complete control to create your lists, design your campaigns, and send them to users just the way you envision. Our top-notch services are available worldwide, serving hundreds of satisfied customers. SmartMails’ experts have thoughtfully crafted an <span className="fw700 text-logo-color2">easy-to-use interface</span>, enabling users to enter the market in the most sophisticated manner!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

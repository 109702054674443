import React from "react";
import blogimg from './images/blog3.jpg';
import blogimg2 from './images/blog4.jpg';
import bg29 from './images/bg-29-top.png';
import pixel from './images/led-px.png';
import pixel2 from './images/led-px2.png';
import messaging_amico_1 from './images/Getintouch-amico.png';
import "./section29.css";

export default function Section29() {
  return (
    <div className="one-section-29">
      <div className="one-section one-section-29-uper">
      <div className="floating-img">
        <img
          src={bg29} alt="bg29"
          className="floating-inner"
        />
      </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-6 text-left position-relative">
                <div className="blk1">
                  <h1 className="fw900 lh-4 text-white max-w800 mx-auto ff2">Turn High Conversion into Reality with Tracking Pixels</h1>
                  <p className="lh-4 margint10 max-w950 mx-auto text-white fs-18">Experience the power of tracking pixels to boost your conversion rates. Simply add the provided HTML code to your emails or website to initiate the tracking process. Tracking pixels allow you to observe and analyze user behavior with your products. Gain valuable insights into user engagement by tracking their actions, such as clicks, adding items to the cart, or completing the checkout process. Take your marketing efforts to the next level with SmartMails and revolutionize your approach in style.</p>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="laptop-img">
                  <img
                    src={pixel2} alt="pixel"
                    className="img-fluid d-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="one-section one-section-29-bottom pt-0" >
        <div className="container">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-12 mt-5">
                <div className="row">
                  <div className="col-md-5 align-self-center mt-3 mx-auto">
                    <h1 className="fw800 lh-4 text-white text-left ff2">
                      <span className="grad-txt">Harness the Power of <span className="fw900">SmartMails Tracking Pixels</span></span>
                    </h1>
                    <p className="fw400 lh-4 fs-20 text-left">Tracking pixels offers an intelligent solution to monitor user activities on your website or application. This advanced method allows you to trace visitor behavior and retarget them effectively by sending targeted emails based on their actions.</p>
                    <p className="fw400 lh-4 fs-20  text-left">Imagine a scenario where a user visits your website, and adds items to their cart, but fails to complete the checkout process. Shockingly, statistics show that 7 out of 10 shopping carts are abandoned, resulting in a significant missed opportunity. Do you want to let a substantial portion of potential buyers slip through your fingers? Take control of your conversions with SmartMails Tracking Pixels and maximize your chances of capturing those valuable customers.</p>
                  </div>
                  <div className="col-md-6 mt-3">
                    <img
                        src={messaging_amico_1} alt="Pixels"
                        className="img-blk"
                      />
                  </div>
                </div>
              </div>
              <div className="col-md-10 text-center mx-auto mb-3">
                <h1 className="fw900 lh-4 mt-5 pt-3 ff2">Embrace SmartMails as Your All-in-One Solution for Visitor Tracking and Automated Email Campaigns.</h1>
              </div>
              <div className="col-md-12 mt-5 mb-5">
                <div className="row">
                  <div className="col-md-6 align-self-center mx-auto mt-3">
                    <img
                      src={pixel} alt="Pixels-messaging"
                      className="img-fluid d-block mx-auto mt-3"
                    />
                  </div>
                  <div className="col-md-6 align-self-center mt-5">
                    <h2 className="fw900 lh-4 text-left ff2">Understanding How Tracking Pixels Works</h2>
                    <p className="fw400 lh-4 fs-20 text-left">SmartMails uses powerful pixel technology to track conversions and optimize ads based on the data collected. A pixel is a code that is placed on your website, enabling you to track conversions from Facebook ads effectively. By leveraging this data, you can build a targeted audience for future ad campaigns, specifically reaching out to qualified leads.</p>
                    <p className="fw400 lh-4 fs-20 text-left">The installation of SmartMails pixel can be done in two ways: utilizing the postback URL to track events or installing the pixel directly on your website, application, or other platforms. This pixel integration allows for precise tracking of occurring events, top events, and monthly activity. By gaining insights into user activities, you can target the most interested users and tailor your marketing efforts accordingly.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="row">
                  <div className="col-md-6 mt-5">
                    <div className="section29-box">
                    <img src={blogimg} className="img-fluid mx-auto d-block recap2" alt="blog-img" />
                      <div className="pading">
                        <ul className="checklist1 text-left">
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Track users' shopping behaviors and interests</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Monitor the open rate of your call to action and user behavior post-visit to your online store <b>online store</b>. </span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Create segments based on location, devices, engagement type, and more</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Target these segments differently to optimize for higher ROI</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Track the types of devices visitors are using</span></div></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="section29-box">
                    <img src={blogimg2} className="img-fluid mx-auto d-block recap2" alt="blog-img2" />
                      <div className="pading">
                        <ul className="checklist1 text-left">
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Gain insights into user location through tracking pixels.</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Determine if users are being directed through paid search channels such as Facebook, Twitter, Google, and more.</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Embed pixels into websites, ads, applications, and other relevant platforms.</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Obtain information about IPs, ad impressions, conversions, and various web activities</span></div></li>
                          <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Track your engagements and earnings with unprecedented accuracy.</span></div></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

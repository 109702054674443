import React from "react";
import bg41 from "./images/bg41.jpg";

import "./section41.css";
export default function Section41() {
  return (
    <div id="main" className="one-section one-section-41" style={{ backgroundImage: `url(${bg41})` }}>
      <div className="container">
        <div className="col-md-12 text-center faq-head">
          <p className="m-auto fs-22 fw900 lh-5 margint10 max-w800 text-blue ff2">Have questions, we’re here to help </p>
          <h1 className="fs-42 fw900 lh-4 text-blue ff2">Frequently Asked Questions</h1>
        </div>
        <div className="col-md-9 m-auto">
          <div className="accordion my-own-cont mt-5" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> What Is SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> SmartMails is a comprehensive email marketing automation solution that helps businesses streamline their email campaigns and reach their target audience effectively.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> How Does SmartMails Work?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> SmartMails allows you to create and automate email campaigns, segment your audience, track engagement metrics, and analyze the effectiveness of your campaigns.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Design Custom Email Templates with SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Absolutely! SmartMails provides a user-friendly drag-and-drop editor that allows you to design professional and visually appealing email templates without any coding knowledge.</div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Schedule Email Campaigns in Advance?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, you can schedule your email campaigns in advance with SmartMails. Set the desired date and time, and SmartMails will automatically send your emails to the selected audience.</div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Does SmartMails Provide Email Personalization Options?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, SmartMails enables you to personalize your emails by adding dynamic content such as the recipient's name, location, or previous purchase history, allowing you to create more targeted and relevant campaigns.</div>
              </div>
            </div>

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingsix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I A/B Test My Email Campaigns with SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Definitely! SmartMails offers A/B testing capabilities, allowing you to test different subject lines, email content, or call-to-action buttons to optimize your campaigns and improve conversion rates.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingsev">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesev" aria-expanded="false" aria-controls="collapsesev">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Does SmartMails Provide Analytics and Reporting Features?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsesev" className="accordion-collapse collapse" aria-labelledby="headingsev" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, SmartMails offers comprehensive analytics and reporting features. You can track key metrics such as open rates, click-through rates, conversion rates, and revenue generated from your email campaigns.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingeig">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeig" aria-expanded="false" aria-controls="collapseeig">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Segment My Email List with SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapseeig" className="accordion-collapse collapse" aria-labelledby="headingeig" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Absolutely! SmartMails allows you to segment your email list based on various criteria such as demographics, past interactions, or purchase behavior. This helps you send targeted emails to specific segments of your audience.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingnine">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Does SmartMails Comply with Email Marketing Regulations?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsenine" className="accordion-collapse collapse" aria-labelledby="headingnine" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, SmartMails adheres to email marketing regulations such as GDPR and CAN-SPAM, ensuring that your email campaigns are compliant with relevant laws and regulations.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingten">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Create Email Workflows with SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapseten" className="accordion-collapse collapse" aria-labelledby="headingten" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Certainly! SmartMails allows you to create complex email workflows by defining triggers, conditions, and actions. This helps you automate your email marketing processes and nurture leads effectively.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingele">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseele" aria-expanded="false" aria-controls="collapseele">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Import My Existing Email List into SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapseele" className="accordion-collapse collapse" aria-labelledby="headingele" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, you can easily import your existing email list into SmartMails using CSV or Excel files. This allows you to seamlessly transition your email marketing efforts to the SmartMails platform.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingtwel">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwel" aria-expanded="false" aria-controls="collapsetwel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Track Conversions and Revenue from My Email Campaigns?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsetwel" className="accordion-collapse collapse" aria-labelledby="headingtwel" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Absolutely! SmartMails provides robust conversion tracking features, allowing you to track the revenue generated from your email campaigns and measure their impact on your business.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingthir">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethir" aria-expanded="false" aria-controls="collapsethir">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Does SmartMails Provide Customer Support?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsethir" className="accordion-collapse collapse" aria-labelledby="headingthir" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, SmartMails offers customer support to assist you with any questions or issues you may encounter. You can reach out to their support team via email, chat, or phone.</div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfort">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefort" aria-expanded="false" aria-controls="collapsefort">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5,18v3.766l1.515-0.909l0,0L11.277,18H16c1.103,0,2-0.897,2-2V8c0-1.103-0.897-2-2-2H4C2.897,6,2,6.897,2,8v8 c0,1.103,0.897,2,2,2H5z M4,8h12v8h-5.277L7,18.234V16H4V8z"></path><path d="M20,2h-1h-2.002H8C6.897,2,6,2.897,6,4h10.586H18c1.103,0,2,0.897,2,2v1.414V12v2c1.103,0,2-0.897,2-2V7V5V4 C22,2.897,21.103,2,20,2z"></path></svg> Can I Create Automated Email Drip Campaigns with SmartMails?
                </button>
                <i className="arrow down"></i>
              </h2>
              <div id="collapsefort" className="accordion-collapse collapse" aria-labelledby="headingfort" data-bs-parent="#accordionExample">
                <div className="accordion-body"><span className="quote"> “ </span> Yes, SmartMails allows you to create automated email drip campaigns, where a series of pre-scheduled emails are sent to subscribers over a specified period, helping you nurture leads and drive conversions.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

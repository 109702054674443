import React from "react";
import newsletterpana from "./images/Newsletter-pana.png";
import GDPR from "./images/GDPR.svg";
import "./section32.css";
export default function Section32() {
  return (
    <div className="one-section one-section-32 p-0">
      <div className="one-section-32a s5" >
        <div className="container">
          <div className="col-md-12 position-relative">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <h1 className="mt-5 ff2 max-w900 h1-blk">Guarantee Email Consent Compliance with GDPR</h1>
                <p className="text-left fs-20 mt-4 text-white pr">SmartMails seamlessly adapts to the requirements of CAN-SPAM, CASL (Canadian Anti-Spam Legislation), and GDPR (General Data Protection Regulation) laws. It effortlessly aligns with spam laws by storing IP address history, geolocation data, and other pertinent recipient engagement information. Rest easy knowing that SmartMails helps you comply with these important regulations.</p>
              </div>
              <div className="col-md-6 align-self-center">
                <img src={GDPR} className="img-fluid mx-auto d-block money" alt="GDPR" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="one-section one-section-32">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center " >
                <h1 className="fs-h1-40 text-white ff2">Create Your Own Opportunity When It Doesn't Knock</h1>
              </div>
            </div>
            <div className="col-md-12 pt-5">
            <div className="row">
              <div className="col-md-6 align-self-center text-white">
                <div className="fw400 lh-4 fs-20 text-left mt-5">SmartMails serves as the gateway to a world of incredible opportunities. Experience the power of increased conversions and sales by leveraging our exceptional features. Whether you're pursuing commercial or governmental objectives, SmartMails deliver remarkable results tailored to your specific needs. Embrace the potential and open the door to endless possibilities.</div>
                <div className="fw400 lh-4 fs-20 text-left mt-3">We shoulder the technical responsibilities so you can focus on achieving success, even if you're not tech-savvy. It's as simple as buying a car and driving it toward your desired destination. With immense potential and user-friendly features, there's no reason to hesitate any longer. Embrace the ease and potential that SmartMails offers, and take your business to new heights.</div>
              </div>
              <div className="col-md-6 mt-3 px-5">
                <img src={newsletterpana} className="img-fluid d-block mx-auto" alt="newsletterpana" />
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react'
import bg21 from './images/bg21.png';
import trigger from './images/trigger-base.png';
import blogs from './images/blog1.png';
import './section21.css';

export default function Section21() {
  return (
    <div className="one-section one-section-21" style={{ backgroundImage: `url(${bg21})` }}>
      <div className="container">
        <div className="row align-center">
          <div className="col-md-12 text-center">
            <h3 className="lh-4 my-margin-t">
              <span className="title3-out">
                <span className="title3">
                  <span className="fw900 ff2">There's More to Come!</span>
                </span>
              </span>
            </h3>
          </div>
          <div className="col-md-8 mx-auto">
            <h1 className="lh-4 fw900 ff2 text-center mt-2 max-w800 mx-auto">Revolutionize Your Email Strategy with <span className='text-dark-blue'>Trigger-Based Emailing</span></h1>
          </div>
          <div className="col-md-12 mt-4">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <img src={trigger} className="img-fluid mx-auto d-block max-w500" alt="trigger" />
              </div>
              <div className="col-md-6 align-self-center">
                <p className="inner-padding lh-4 gray fs-20 pr-text">Trigger-based emailing refers to an automated response triggered by a user's specific action. Use trigger emails in SmartMails to automatically inform users about certain events. With a variety of customizable filters at your disposal, you can tailor the triggers to your specific preferences.</p>
                <p className="inner-padding lh-4 gray fs-20 pr-text">For instance, if you choose the event criterion "Contact is added to a List," you can select the desired action, such as sending a broadcast, initiating a drip campaign, or sending a notification email to the admin. Once a user is added to the designated email list, the corresponding trigger will be executed promptly.</p>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-5">
            <div className="row ">
              <div className="col-md-6 align-self-center pl-text">
                <h1 className='ff2 fw800'>Deliver <span className="fw900 heading-blk">Timely and Targeted</span> Information to Your Audience</h1>
                <ul className="checklist1 mt-5">
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Connect with over <b>4 billion users</b> globally and expand your reach.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Increase your revenues by a staggering <b>4200%</b>.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center"><b>64%</b> of brands utilize Email Marketing to effectively target customers.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center"><b>37%</b> of businesses are allocating more budget towards Email Marketing.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Email is 40 times <b>more impactful than social media</b> in generating results.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">The buying process is <b>three times faster</b> through email compared to social media.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Email enables personalized <b>one-on-one communication</b>.</span></div></li>
                  <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Enjoy <b>cost-effective</b> marketing compared to other channels.</span></div></li>
                </ul>
              </div>
              <div className="col-md-6 align-self-center">
              <img src={blogs} className="img-fluid mx-auto d-block max-w500" alt="recap" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import React from 'react'
import conn3 from './images/connection3.png';
import conn4 from './images/connection4-1.png';
import titlebg15 from './images/titleBg2.png';
import amico from "./images/Emailcampaign-amico.png";
import perfum from "./images/perfum.png";
import './section15.css';

export default function Section15() {
  return (

    <div className="one-section-15">
      <div className="one-section one-section-15-inner pt-0">
        <div className="col-md-12 text-center">
          <h3 className="lh-4 my-margin-t ff2"> <span className="title3-out"><span className="title3"><span className="text-white">Still Not Ready? Read Something More Enticing</span> </span></span> </h3>
        </div>
        <div className="col-md-12 text-center"> <img src={conn3} className="img-fluid mx-auto d-block connection3" alt="connection3" /></div>
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="titleBg2 lh-4 fw400 text-center max-w950 mx-auto" style={{ backgroundImage: `url(${titlebg15})` }}>
              <h1 className="fw700 lh-4 text-sea-green mb-0 fs-h1-42 ff2">Double your sales just like <span className="fw900">FeelVibes</span></h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="lh-4 fw400 text-center pera1 mt-5">
                FeelVibes, a luxury perfume brand, faced the issue of cart abandonment despite its attractive product line and user-friendly website. To tackle this challenge, they turned to SmartMails, to implement a solution to reduce cart abandonment rates and increase sales.
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12">
                  <img src={amico} className="img-fluid d-block mx-auto max-w550 mt-5" alt='SmartMails-img' />
                </div>
                <div className="col-md-6 col-sm-12">
                  <p className="text-white fw400 lh-4 text-align-left mt-5">The primary objective for FeelVibes was to recover abandoned carts and improve conversion rates. They integrated SmartMails into their e-commerce platform, automating the process of sending targeted cart abandonment emails. Their email strategy included personalized product recommendations, limited-time discounts, and compelling calls to action to remind customers of their abandoned carts and encourage them to complete their purchases.</p>
                  <p className="text-white fw400 lh-4 text-align-left">They experienced a notable increase in conversion rates due to the personalized and timely nature of the emails, leading to a 20% improvement within the first month. Moreover, the cart abandonment rates decreased by 35% as a result of successfully persuading customers to return and finalize their purchases.</p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12">
                  <p className="text-white fw400 lh-4 text-align-left mt-5">SmartMails enabled FeelVibes to establish stronger customer connections. Personalized recommendations and exclusive discounts made customers feel valued and motivated them to engage further with the brand. This resulted in higher email open rates and click-through rates, indicating improved customer engagement overall.</p>
                  <p className="text-white fw400 lh-4 text-align-left">FeelVibes successfully addressed the challenge of cart abandonment by using SmartMails' targeted and personalized cart abandonment emails. Their implementation resulted in reduced cart abandonment rates, increased conversion rates, and revenue growth. They also improved customer engagement and satisfaction. Feel Vibes continues to use SmartMails as an integral part of its email marketing strategy, driving sales and fostering long-term customer relationships.</p>
                </div>
                <div className="col-md-6 col-sm-12">
                  <img src={perfum} className="img-fluid d-block mx-auto max-w550 mt-5" alt='SmartMails-img' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3 className="fw800 problem-bg1 text-white text-center mywidthcom ff2">Are You Ready to Take Off?</h3>
      <div className="row justify-content-center m-0 mt8">
        <div className="col-md-12"><img src={conn4} className="img-fluid mx-auto d-block connection4" alt="connection4" /></div>
      </div>
    </div>
  );
}

import React from "react";
import mamico from "./images/Messaging-amico.png";

import "./section33.css";
export default function Section33() {
  return (
    <div className="one-section one-section-33">
      <div className="container">
        <div className="col-md-12">
          <div className="row justify-content-center">
            <div className="col-md-6 align-self-center">
              <img src={mamico} className="img-fluid max-w500 mx-auto d-block money" alt="money" />
            </div>
            <div className="col-md-6 align-self-center">
              <h2 className="fw900 lh-4 text-white ff2">Begin Your Journey with SmartMails - Absolutely Free!</h2>
              <p className="fs-20 fw400 lh-4 m-0 text-white">Enjoy your cost-effective email marketing with SmartMails</p>
              <ul className="checklist1 mt-5 text-left text-white">
                <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Sign up for SmartMails’ free account</span></div></li>
                <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Design your email campaign</span></div></li>
                <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Send your campaigns instantly</span></div></li>
                <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Upgrade when you start reaping the fruit of the best results</span></div></li>
                <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Send your emails whenever you want</span></div></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import emailcampaign from "./images/Emailcampaign-bro.png";
import imgpayload from "./images/Envelope-api.png";
import "./section10.css";
export default function Section10() {
  return (
    <div className="one-section one-section-10">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center max-w800">
          <h1 className="fs-h1-42 lh-4 fw500 text-center text-white ff2">Send Your <span className="fw900 text-yellow">Transactional Emails</span> Using <span className="fw800 text-white">SMTP</span> or <span className="fw800 text-white">API Integration</span>	</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 ">
            <div className="row align-items-center mb-5">
              <div className="col-md-6 col-sm-12 mt-5">
                <img src={emailcampaign} className="img-fluid" alt="my-img" />
              </div>
              <div className="col-md-6 col-sm-12 mt-5">
                <div className="lh-4 fw400  ">
                  <h3 className="fs-36 fw800 lh-4 text-white ff2">Send Via <span className="fw700 text-yellow">SMTP</span></h3>
                </div>
                <div className="lh-4 fw400 text-white">
                  <div className="fw400 lh-4  pera">
                  <p className="fw400 lh-4">Utilize the Simple Mail Transfer Protocol (SMTP) to send your emails seamlessly. SmartMails allows you to create a bridge of dedicated IPs and send your emails to the intended destinations. By establishing an SMTP connection, you can easily connect your website or application to SmartMails and enjoy efficient email delivery.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12">
                <div className="lh-4 fw400  ">
                  <h3 className="fs-36 fw800 lh-4 text-white ff2">Send Via <span className="fw700 text-yellow">API</span></h3>
                </div>
                <div className="fw400 text-white pera">
                  <p className="fw400 lh-4">Use the power of the Application Programming Interface (API) to send emails seamlessly through SmartMails. APIs enable different application systems to communicate and automate the process of sending transactional emails. By integrating your application or website with SmartMails using the API key, you can send transactional emails in real time without any delay. Embrace the latest technology and leverage the SmartMails APIs for enhanced email delivery.</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 mt1   ">
                <img src={imgpayload} className="img-fluid" alt="my-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import bg25 from "./images/bg25.jpg";
import landingpage from "./images/led-lp.png";
import landingpage2 from "./images/led-lp2.png";
import "./section25.css";
export default function Section25() {
  return (

    <div className="one-section one-section-25" style={{ backgroundImage: `url(${bg25})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 mb-5 text-center">
            <h1 className="fs-42 fw900 lh-4 text-white ff2">Unleash Your Website's Creativity</h1>
            <div className="fs-20 fw400 lh-4 text-white">SmartMails is dedicated to providing users with a distinct sense of accomplishment, which sets them apart from the competition. We go above and beyond to ensure that you stay one step ahead. With our collection of creative and responsive landing page templates, we empower you to convert leads into sales. By guiding users towards predefined goals and strategically placing specific calls to action, we enable seamless redirection to desired items or offers.</div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="img-styling">
                <img
                  src={landingpage} alt="landingpage"
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center padding-bottom">
            <div className="col-md-6 mt-5 align-self-center px-4">
              <h1 className="lh-4 text-white ff2">Select a <span className="heading-blk fw900">High-Converting</span> Landing Page</h1>
              <p className="fw400 text-white fs-20">Select a customizable landing page template and effortlessly edit it using the intuitive Drag & Drop Builder. SmartMails simplifies the entire process, allowing you to select, edit, promote, and optimize your landing page without requiring any coding knowledge. Start selling your products and services today and prepare to generate revenue. Attract more subscribers and convert them into valuable sales, thereby boosting your business profits. To enhance your professional image, easily connect your custom domain in just a few simple steps.</p>
            </div>
            <div className="col-md-6 mt-5 align-self-center">
              <img src={landingpage2} className="img-fluid d-block mx-auto" alt="SmartMails-img" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="fs-20 fw600 lh-4 mt-3 text-center box-style-sec max-w950 mx-auto">The main objective of using a landing page is to minimize user navigation and maintain their focus on specific products, rather than diverting their attention away from the page.</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

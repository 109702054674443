import React from "react";
import envelope from "./images/Transactional-email.jpg";
import invite from "./images/Transactional-include.png";
import editingbodytext from "./images/Perfect-solution.jpg";
import contactus from "./images/start-transactional.jpg";
import wishes from "./images/triggered-based.jpg";
import bg7 from "./images/bg7.png";
import "./section7.css";

export default function Section7() {
  return (
    <div className="one-section one-section-7" style={{ backgroundImage: `url(${bg7})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h1 className="fw600 text-dark-blue ff2">
              <span className="fw900 ">Transactional</span> Email Services
            </h1>
            <p className="mx-auto fs-20 fw400 lh-4 margint10" style={{maxWidth:850}}>Experience the true pleasure of success with SmartMails’ reliable transactional email services and powerful APIs. They are always available to cater to your needs.</p>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-5">
                <div className="section29-box">
                  <img src={envelope} className="img-fluid mx-auto d-block recap1" alt="recap" />
                  <div className="pading">
                    <h1 className="fs-22 fw900 lh130 ff2">Gain Insights into Transactional Email</h1>
                    <p className="inner-padding lh-4 gray mb-0">
                      A transactional email is an email message that is <b>automatically generated</b> in response to a specific action taken by a user on a website or mobile app. These emails are individually sent to users and contain specific information or data relevant to their actions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <div className="section29-box">
                  <img src={wishes} className="img-fluid mx-auto d-block recap2" alt="recap" />
                  <div className="pading">  <h1 className="fs-22 fw900 lh130 ff2">What should you expect:</h1>
                    <ul className="checklist2 fw400 my-text-left gray lh-4  margint10 mb-0">
                      <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Password reset emails</span></div></li>
                      <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Order confirmation</span></div></li>
                      <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Welcome/registration emails</span></div></li>
                      <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Cart abandonment emails</span></div></li>
                      <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Social media updates</span></div></li>
                      <li><div className="d-flex"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Notification emails</span></div></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-12 ">
                <div className="blk-box">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 m-auto text-center">
                      <img src={invite} className="img-fluid d-block recap3" alt="recap" />
                    </div>
                    <div className="col-md-6 col-sm-12 m-auto text-center">
                      <h1 className="fs-22 fw900 lh130 text-left ff2">Send Automated Emails</h1>
                      <p className="fw400 lh-4 pad-t gray mb-0 text-align-left pe-md-2">
                        Use the highly efficient email automation system of SmartMails to send your transactional emails seamlessly. With SmartMails, you can send transactional emails using either <b>SMTP or an API</b>. It's important to note that transactional emails differ from marketing emails in terms of how they are sent. Transactional emails are triggered in real-time based on specific user actions, such as a password reset, while marketing emails are sent to a wider audience for product promotions, sales, and services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="row justify-content-center">
              <div className="col-md-6 mt-5">
                <div className="section29-box">
                  <img src={editingbodytext} className="img-fluid d-block recap1" alt="recap" />
                  <div className="pading">
                  <h1 className="fs-22 fw900 lh130 ff2">Providing the Ideal Solution for Your Needs</h1>
                    <p className="inner-padding lh-4 gray mb-0">
                    Sending transactional emails becomes effortless when you choose the right transactional email service. This is where SmartMails comes in, offering a <b>comprehensive solution</b> for your transactional emails. With SmartMails, you gain control over sending and tracking these emails, ensuring a seamless experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <div className="section29-box">
                  <img src={contactus} className="img-fluid mx-auto d-block recap2" alt="recap" />
                  <div className="pading">
                  <h1 className="fs-22 fw900 lh130 ff2">Start on Your Transactional Email Journey Today</h1>
                    <p className=" lh-4 gray mb-0">To ensure the timely delivery of your transactional emails, your brand deserves a <b>reliable email marketing tool</b>. SmartMails grants you the autonomy to achieve precisely that. If you're looking to enhance your open rate, expand your reach, and drive more sales, start sending transactional emails through SmartMails today!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

import React from "react";
import emailcamp from "./images/shared_ip.png";
import emailcamp2 from "./images/dedicated_ip.png";
import emailcap from "./images/dedicated_pool.png";
import bg9 from "./images/bg-9.jpg";
import "./section9.css";

export default function Section9() {
  return (
    <div className="one-section one-section-9 " style={{ backgroundImage: `url(${bg9})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
          <h1 className=" fw900 ff2 lh-4 text-white">Explore the Versatile IP Deployment Options Available</h1>
            <p className="mx-auto  fw400 lh-4 text-white max-w800 mt-2 mb-5">SmartMails provides you with the flexibility of choosing between shared IPs and dedicated IP addresses to meet your specific requirements.</p>
          </div>

          <div className="col-md-12 ">
            <div className="mx-auto section9-box my-padding-section-top my-padding-section-bottom mt-4">
              <div className="col-lg-12 col-md-12 col-md-12 inner-section section-even">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12 margint10">
                    <div className="fs-22 lh-4 fw800 ff2 text-white mb-3">What to Expect from <b className="text-blk fw900">Shared IPs ?</b></div>
                    <div className=" fw400 text-white">
                      <p>Shared IP addresses are similar to public highways. While they allow you to reach your destination quickly and send messages instantly, the actions of other users sharing the same IP can sometimes slow down or even halt your progress. Opting for shared IPs can be a cost-effective solution, as there are no extra expenses involved. However, it's important to note that deliverability may be affected due to the presence of multiple users on the shared IP.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src={emailcamp} className="img-fluid" alt="img-sec9" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-md-12 inner-section section-odd">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12  order-md-2">
                    <div className="fs-22 lh-4 fw800 text-white ff2 mb-3"><b className="text-blk fw900">Dedicated IPs</b> for Enhanced Deliverability</div>
                    <div className="lh-4 fw400 black margint10 pr-md-4">
                      <div className="fw400 text-white pr-md-3">
                      <p>A dedicated IP address is comparable to a private highway, exclusively designed for your use, providing unrestricted freedom to drive or fly smoothly. By opting for a dedicated IP with your SmartMails account, you have the opportunity to build and manage your reputation. If you anticipate sending a substantial volume of emails, we recommend using dedicated IPs to ensure direct delivery to recipients' mailboxes.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12  order-md-1">
                    <img src={emailcamp2} className="img-fluid" alt="img-sec9" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-md-12 inner-section section-even">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12   ">
                    <div className="fs-30 lh-4 fw800 text-white ff2 mb-3"><b className="text-blk fw900">Dedicated IP Pools</b> for High-Volume Sending</div>
                    <div className="fw400 text-white margint10 pr-md-3">
                      <p>In addition to dedicated IPs, SmartMails offers the option to create dedicated pools consisting of multiple dedicated IPs that can be used based on your specific needs. With floating IPs in the dedicated pools, you can send transactional and marketing emails separately, utilizing different IPs. For high-volume senders, creating a bridge in SmartMails enables the sending of transactional emails using SMTP or API. Using a dedicated IP pool, you can effectively group your dedicated IP addresses, gaining greater control over deliverability.</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src={emailcap} className="img-fluid" alt="img-sec9" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-md-12 inner-section-2 section-odd">
                <div className="row align-items-center">
                  <div className="col-md-6 col-sm-12 order-md-2">
                    <div className="blk-list text-align-left">
                      <div className="fs-36 lh-4 text-dark-blue fw800 ff2">Order Dedicated IPs</div>
                      <div className="lh-4 fw400 margint10 mb-3 text-white">
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className=" list-paddingx">Log in to your SmartMails account</p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className=" list-paddingx">Navigate to <b>Setup</b>, and click then <b>Dedicated IPs</b></p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className=" list-paddingx">Now click Order IPs</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 ">
                    <div className="blk-list text-align-left">
                      <div className="fs-36 lh-4 text-dark-blue fw800 ff2">Dedicated Pools</div>
                      <div className="lh-4 fw400 mb5 margint10 text-white mb-3">
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className=" list-paddingx">Log in to your SmartMails account</p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className=" list-paddingx">Navigate to <b>Transactional→Create a Bridge</b></p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className=" list-paddingx">Name your <b>dedicated pool</b>, select a pool, and click Create.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

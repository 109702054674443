import React from 'react'
import arrow from "./images/arrow.png";
import paytm from "./images/paytm.png";
import './section8.css';

export default function Section8() {
  return (
    <div className="one-section one-section-8">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section8-box max-w950 m-auto">
            <div className="section8-box-inn">
              <div className="row justify-content-around m-0">
                <h3 className="fw800 mt-2 text-center ff2">
                  <em>Start Your Journey with <span className="fw900 text-dark-blue">SmartMails</span></em></h3>
                <div className="col-md-12 text-center mt-4">
                  <div className="mybutton">
                    <a href="#pricing" className="text-center fw400 text-uppercase"> <span className="mybutton-btn">
                      GET STARTED NOW
                      <img
                          src={arrow} alt="arrow"
                          className="img-fluid center-block btn-arrow"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <img
                      src={paytm} alt="payment"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

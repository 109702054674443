import React from "react";
import connection5 from "./images/connection5.png";
import connection5b from "./images/connection5-b.png";
import emailcapture from './images/sending-domain.png';
import emailcampaign from './images/Emailcampaign-rafiki.png';
import sentMessage from './images/SentMessage-rafiki.png';
import onlinewishes from './images/Onlinewishes-rafiki1.png';
import "./section18.css";
export default function Section18() {
    return (
        <div className="one-section one-section-18 pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="odd-section mx-auto mt-1">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <h1 className="lh-4 fw900 mt-20 ff2"><span className="heading-blk">Add Your Sending Domain</span></h1>
                                    <p className="fw400 text-white margint10 lh-4 fs-20">Sending domains are crucial for email communication as they determine the domain displayed in message headers, giving the impression that emails are sent from these domains. By adding a sending domain to SmartMails, the platform automatically generates DNS keys to authenticate the domain.</p>
                                </div>
                                <div className="col-md-6 align-self-center">
                                    <img
                                        src={emailcapture} alt="problems"
                                        className="img-fluid mx-auto d-block img-style"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <img
                            src={connection5} alt="connection"
                            className="img-fluid mx-auto d-block connection5"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row even-section mx-auto">
                            <div className="col-lg-6 col-md-6 align-self-center problem-text mt-20 order-md-2">
                                <h1 className="lh-4 fw900 ff2"><span className="heading-blk">Add Your Contact List</span></h1>
                                <p className="fw400 margint10 lh-4 fs-20 text-white">A contact list is a centralized repository for storing your leads and their essential information. It enables you to segment your list effectively, ensuring that you can deliver tailored information to the appropriate audience.</p>
                            </div>
                            <div className="col-md-6 align-self-center order-md-1">
                                <img
                                    src={emailcampaign} alt="emailcampaign"
                                    className="img-style img-fluid mx-auto d-block"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <img
                            src={connection5b} alt="connection"
                            className="img-fluid mx-auto d-block connection5"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row odd-section mx-auto">
                            <div className="col-lg-6 col-md-6 align-self-center problem-text mt-20">
                                <h1 className="lh-4 fw900 ff2"><span className="heading-blk">Design your Broadcast</span></h1>
                                <p className="fw400 margint10 text-white lh-4 fs-20">A broadcast refers to an email template containing TEXT/HTML body content that is sent to your recipients. You have the flexibility to create your broadcast using either the HTML Editor or the Drag & Drop Builder in SmartMails.</p>
                            </div>
                            <div className="col-md-6 align-self-center">
                                <img
                                    src={sentMessage} alt="sentMessage"
                                    className="img-fluid mx-auto d-block img-style "
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <img
                            src={connection5} alt="connection"
                            className="img-fluid mx-auto d-block connection5"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row even-section mx-auto">
                            <div className="col-lg-6 col-md-6 align-self-center problem-text order-md-2 mt-20">
                                <h1 className="lh-4 fw900 ff2"><span className="heading-blk">Send Your Broadcast</span></h1>
                                <p className="fw400 margint10 lh-4 fs-20 text-white">Once you have added a contact list and configured the sending domain, you are all set to schedule the broadcast that you have already designed. Simply provide the necessary information in the required fields, and choose to either send your broadcast instantly or schedule it for a later time.</p>
                            </div>
                            <div className="col-md-6 align-self-center order-md-1">
                                <img
                                    src={onlinewishes} alt="onlinewishes"
                                    className="img-fluid mx-auto d-block img-style"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react";
import subscriptions_pana1 from './images/Subscriptions-pana1.png';
import bg39 from './images/bg39.jpg';
import "./section39.css";
export default function Section40() {
  return (
    <div className="one-section one-section-39" style={{ backgroundImage: `url(${bg39})` }}>
      <div className="container">
        <div className="col-lg-12 col-md-12">
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <img
                src={subscriptions_pana1} alt="Subscriptions-pana1"
                className="img-fluid d-block mx-auto mt-3 max-w500"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="max-w650 mt-3 m-auto">
                <h2 className="fw600 lh-4 text-black ff2">SmartMails empowers you to easily <span className="text-light-blue fw900">view and analyze the statistics </span> of your sent Broadcasts</h2>
                <div className="lh-4 fw400 text-black mt-4 text-left">
                  <div className="css-hv626q">
                    <div className="check-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                    </div>
                    <p className="css-pnvbje">Access comprehensive stats for your broadcasts and triggers</p>
                  </div>
                  <div className="css-hv626q">
                    <div className="check-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                    </div>
                    <p className="css-pnvbje">Identify the geographical locations where your emails are opened or clicked</p>
                  </div>
                  <div className="css-hv626q">
                    <div className="check-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                    </div>
                    <p className="css-pnvbje">Evaluate the performance of different ISPs based on open rates and address any delivery issues</p>
                  </div>
                  <div className="css-hv626q">
                    <div className="check-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                    </div>
                    <p className="css-pnvbje">Stay informed with detailed statistics on the success of your email broadcasts, including sent, delivered, opened, clicked, delayed, failed, and complaint metrics.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from "react";
import bg20 from "./images/bg20.jpg";
import feature from "./images/mind-blowing.png";
import "./section20.css";
export default function Section20() {
    return (
        <div className="one-section one-section-20" style={{ backgroundImage: `url(${bg20})` }} >
            <div className="one-section-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <img
                                src={feature} alt="squad"
                                className="img-fluid mx-auto d-block squad"
                            />
                        </div>
                        <div className="col-md-6 mt-5 text-white">
                            <h1 className="fs-42 fs-45 max-w550 lh-4 fw700 ff2">Explore an Unbelievably <span className="inner_blk fw900">Mind-Blowing Read</span></h1>
                            <p className="fw400 fs-20 margint10 lh-4">Over the past decade, email marketing automation tools have made a significant impact. SmartMails has elevated this process to an advanced level, offering remarkable revenue multiplication opportunities. Our platform provides a mind-blowing machine equipped with ready-to-use features that can tirelessly work for you 24/7/365.</p>
                            <p className="fw400 fs-20 margint10 lh-4">When designing the interface, we prioritized user-friendliness, ensuring that even non-technical individuals can easily navigate and use it. With SmartMails, you can automate follow-up emails effortlessly based on specific timeframes and intervals for your lists or segments.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react";
import envelope_amico from './images/Envelope-amico.png';
import envelope_cuate from './images/Envelope-cuate.png';
import getintouch_amico1 from './images/Getintouch-amico1.png';
import bg30 from './images/bg30.jpg';

import "./section30.css";
export default function Section30() {
  return (
    <div className="one-section one-section-30" style={{ backgroundImage: `url(${bg30})` }}>
      <div className="feature-section2 my-padding-section-top-bottom">
        <div className="container">
          <div className="col-md-12 mx-auto">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12 col-12">
                  <h1 className="lh-4 fw900 max-w550 text-black mx-auto ff2">Experience What You Truly Deserve</h1>
                  <p className="fs-20 lh-4 fw400 text-black mt-3 mx-auto max-w550">Experience the full force of SmartMails as we provide you with robust, high-performance features. Give your business the final push it needs to thrive in today's competitive landscape and elevate your brand to new heights.</p>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <img
                    src={envelope_amico} alt="envelope_amico"
                    className="img-fluid d-block mx-auto"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12 col-12 mt6 order-md-2 px-md-0">
                  <h1 className="lh-4 fw900 text-black max-w550 m-auto mt-5 ff2">Choose Your Desired Plan</h1>
                  <h5 className="lh-4 fw500 text-black max-w550 m-auto ">&amp; Start Generating Profits with a Few Simple Clicks</h5>
                  <div className="lh-4 fw400 text-black mt-4 pr-md-4 max-w550 m-auto text-left">
                    <ul className="checklist1 text-left">
                      <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Design your campaigns</span></div></li>
                      <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Decide your audience</span></div></li>
                      <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Send it right to the mailboxes</span></div></li>
                      <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Get ready for conversions</span></div></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 col-12 mt6 order-md-1">
                  <img
                    src={envelope_cuate} alt="envelope_cuate"
                    className="img-fluid d-block"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12 col-12 mt-5">
                  <h1 className="lh-4 fw900 max-w550 text-black mx-auto ff2">Expand Your Email List and Boost Email Sending</h1>
                  <p className="fs-20 lh-4 fw400 text-black mt-3 mx-auto max-w550">With SmartMails, growing your email list becomes a breeze. We offer user-friendly and responsive web forms that make it easy to collect email addresses and add them to your mailing list. Enhance your database with captivating and ready-to-use subscription forms that are designed to grab users' attention. Experience the power of effortless email list growth with SmartMails.</p>
                </div>
                <div className="col-md-6 col-sm-12 col-12">
                  <img
                    src={getintouch_amico1} alt="getintouch_amico1"
                    className="img-fluid d-block mx-auto mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import paytm from "./images/paytm1.png";
import './section6.css';

export default function Section41() {
  return (
    <div className="one-section one-section-6">
      <div className="container">
        <div className="col-md-12">
          <div className="row">
            <h1 className="fw700 ff2 mt-2 text-white text-center">Get Started with <span className="fw900 ">SmartMails</span>
            </h1>
            <div className="mybutton2 mt-4">
              <a href="#pricing" className="text-center text-uppercase">
                <h2 className='mt-2 mb-2 text-link fw600'>Sign Up Now</h2>
              </a>
            </div>
            <div className='col-md-12'>
            <img src={paytm} alt="payment" className="img-fluid mx-auto d-block" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from "react";
import Logo from './logo.png';
import Icon from './icon.png';
import './footer.css';

const Footer =()=> {
    return(
        <footer>
            <div className="footer">
                <div className="footer-bg"></div>
                <div className="container">
                    <div className="row footer-row">
                        <div className="col-md-5">
                            <div className="footer-logo-blk">
                                <img src={Logo} className="footer-logo" alt="Smartmails Footer Logo" />
                                <p className="footer-desc">
                                    Welcome to SmartMails, a leading email marketing platform designed to help businesses of all sizes create and execute effective email campaigns. Our mission is to empower our users with the tools and resources they need to engage with their audience, drive sales, and achieve their marketing goals.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-4">
                                    <ul className="menu-footer">
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/">Home</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/about/">About Us</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/blog/">Blog</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/contact/">Contact</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <ul className="menu-footer">
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/about/#helping">Helping Hand</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/about/#what">What We Do</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/about/#faq">FAQs</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <ul className="menu-footer">
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/privacy/">Privacy Policy</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                        <li className="menu-footer-list">
                                            <a className="menu-footer-link" href="https://smartmails.io/terms/">Terms of Services</a>
                                            <span className="menu-background-line"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="copyright">
                            <img src={Icon} alt="Smartmails copyrights" className="footer-icon" />© All rights reserved. • Smart Mails
                        </div>
                        <div className="footer-social">
                            <a href="https://www.facebook.com" className="social-fb" target="_blank" rel="noreferrer" title="Facebook">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" height="16" width="16" viewBox="0 0 310 310" ><g id="XMLID_834_"><path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064 c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996 V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545 C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703 c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z" /></g></svg>
                            </a>
                            <a href="https://www.twitter.com" className="social-tw" target="_blank" rel="noreferrer" title="Twitter">
                                <svg fill="#FFFFFF" height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310"><g id="XMLID_826_"><path id="XMLID_827_" d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73 c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783 c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598 C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467 c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977 c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889 c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597 c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961 c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895 c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367 c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998 C307.394,57.037,305.009,56.486,302.973,57.388z" /></g></svg>
                            </a>
                            <a href="https://www.linkedin.com" className="social-in" target="_blank" rel="noreferrer" title="Linkedin">
                                <svg fill="#FFFFFF" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6.975,20.667H3.117V9.059H6.975ZM5.072,3.462a2.011,2.011,0,1,0-.051,4.012h.026a2.012,2.012,0,1,0,.025-4.012ZM9.111,20.667h3.858V14.185a2.639,2.639,0,0,1,.127-.941,2.111,2.111,0,0,1,1.98-1.411c1.4,0,1.955,1.064,1.955,2.625v6.209h3.858V14.011c0-3.565-1.9-5.225-4.442-5.225A3.828,3.828,0,0,0,12.97,10.7V9.059H9.111c.051,1.089,0,11.609,0,11.609Z" /></svg>
                            </a>
                            <a href="https://wa.me/+18446862721" className="social-wa" target="_blank" rel="noreferrer" title="Whatsapp">
                                <svg fill="#FFF" height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308 308"><g id="XMLID_468_"><path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156 c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687 c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887 c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153 c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802 c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922 c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0 c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458C233.168,179.508,230.845,178.393,227.904,176.981z"/><path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716 c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396 c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188 l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867 C276.546,215.678,222.799,268.994,156.734,268.994z"/></g></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React from 'react'
import './section23.css';

export default function Section23() {
  return (
    <div className="one-section one-section-23 triangle">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="fw600 lh-4 text-center text-white fs-42 ff2"><span className='text-yellow fs-60'>Impressed?</span><br /> Prepare to Be Amazed by <span className='fw900'>Countless More Features</span></div>
            <div className="fw400 text-center text-white fs-22 mb-4">What more do you require to <span className='fw700'>stand toe-to-toe</span> with your competitors in the <span className='fw700'>business arena?</span></div>
          </div>

        </div>

      </div>
    </div>
  );
}
import React from 'react'
import bg14 from './images/bg14.jpg';
import connection14 from './images/connection3.png';
import titleBg14 from './images/titleBg1.png';
import emailcampaignbro from "./images/Email-campaign-bro.png";
import emailcampaignraf from "./images/Emailcampaign-rafiki1.png";
import './section14.css';

export default function Section14() {
  return (
    <div className="one-section one-section-14" style={{ backgroundImage: `url(${bg14})` }}>
      <div className="container">
        <div className='col-md-12'>
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <h1 className="mt-2 mb-2 mx-auto lh-4 new-sec-small-width"> <span className="title3-out"><span className="title3"><span className="text-dark-blue ff2 fw900">Need a Proof?</span> </span></span> </h1>
            </div>
            <div className="col-md-12 text-center"> <img src={connection14} className="img-fluid mx-auto d-block connection3" alt="connection3" /> </div>
          </div>
          <div className="col-md-12">
            <div className="row mt-2">
              <div className="col-md-6 col-lg-6 my-pad-left70">
                <div className="col-md-12 titleBg1 my-margin-left" style={{ backgroundImage: `url(${titleBg14})` }}>
                  <h2 className="fw900 lh-4 text-white fs-24 ff2">An Engaging Case Study Worth Your Time</h2>
                </div>
                <p className="fw400 lh-4 mt-4">New Woven, a well-established clothing brand, was facing challenges in reaching its targeted audience and achieving desired sales figures. Despite offering quality products, the brand was struggling to effectively communicate its value proposition and drive customer engagement. To overcome these obstacles, New Woven decided to leverage the power of SmartMails to enhance its sales performance.</p>
                <p className="fw400 lh-4 mt-3">The objectives of implementing this email automation tool were to improve targeting, increase engagement, and boost sales. New Woven analyzed customer data, segmented its customer base, designed visually appealing emails by using email templates, and automated campaigns, and tracked performance using SmartMails.</p>
              </div>
              <div className="col-md-6 col-lg-6"> <img src={emailcampaignbro} className="img-fluid center-block important max-w500" alt="important" /> </div>
            </div>
          </div>
          <div className="col-md-12 pading">
            <div className="row justify-content-around">
              <div className="col-lg-6 col-md-6 order-md-2 align-self-center problem-text">
              <div className="blk-list text-align-left">
                  <div className="fs-36 lh-4 fw600 text-dark-blue ff2 fw900">Outcomes and Results</div>
                  <div className="lh-4 fw400 mb5 margint10 pr-md-4">
                    <div className="d-flex">
                      <div className="check-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                      </div>
                      <p className="list-paddingx">SmartMails' targeting capabilities helped New Woven reach a specific and relevant audience, resulting in higher engagement rates and click-throughs.</p>
                    </div>
                    <div className="d-flex">
                      <div className="check-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                      </div>
                      <p className="list-paddingx">Personalized emails improved interaction and brand loyalty, offering tailored content, exclusive offers, and personalized recommendations.</p>
                    </div>
                    <div className="d-flex">
                      <div className="check-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                      </div>
                      <p className="list-paddingx">Targeted and engaging content led to a notable increase in conversion rates, aided by SmartMails' automation features.</p>
                    </div>
                    <div className="d-flex">
                      <div className="check-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                      </div>
                      <p className="list-paddingx">SmartMails' analytics allowed New Woven to track and measure campaign success, optimizing future marketing strategies.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 order-md-1 align-self-center"> <img src={emailcampaignraf} className="img-fluid mx-auto d-block image1 max-w500" alt="email" /> </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row mt-2">
              <div className="col-md-10 mx-auto">
              <h3 className="fw400 fs-22 gray text-center mx-auto">New Woven implementation of SmartMails resulted in significant improvements, including increased reach, enhanced customer engagement, improved conversion rates, and measurable success. SmartMails proved to be an effective tool for driving sales and business growth.</h3>
                <p className="fw900 text-center inner_box ff2">Are you prepared to discover your path, much like New Woven did? We sincerely hope that you are!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
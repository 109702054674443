import React from "react";
import emails_cuate_1 from './images/Emails-cuate-1.png';
import newsletter_amico from './images/Newsletter-amico.png';
import webhook from './images/webhook_pages.png';
import connection11 from './images/connection11.png';
import bg37 from './images/bg37.jpg';

import "./section37.css";
export default function Section37() {
  return (
    <div className="one-section one-section-37" style={{ backgroundImage: `url(${bg37})` }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mt-20-responsive">
            <h1 className="fw700 text-center text-white ff2"><span className="title3-out"><span className="title3">Hold On! Here's the Most Exciting Part</span></span></h1>
          </div>
          <div className="col-md-12 text-center">
            <img
              src={connection11} alt="connection"
              className="img-fluid mx-auto d-block connection11"
            />
            <h1 className="fw900 lh-4 text-blue ff2">Receive Real-Time Notifications with Webhooks</h1>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="row justify-content-center margint10">
              <div className="col-md-6 align-self-center">
                <img
                  src={webhook} alt="bonus"
                  className="img-fluid d-block mx-auto mt-5 max-w550"
                />
              </div>
              <div className="col-md-6 align-self-center bonus-text">
                <p className="lh-4 fw400 text-off-white fs-20">Automation is not just about the actions you take within your account. With SmartMails webhook feature, you can extend your automation capabilities by connecting your workflow with third-party applications. Receive real-time status notifications to your endpoints whenever specific events occur, matching your predefined criteria. Take your automation to the next level with webhooks in SmartMails.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="row justify-content-center ">
              <div className="col-md-6 align-self-center bonus-text">
                <p className="lh-4 fw400 text-off-white fs-20">With the webhook feature, you have the flexibility to select specific events for which you want to receive status notifications. Whether it's for emails being received, delivered, bounced, delayed, or generating complaints, you can stay informed about every crucial stage of your email campaigns. Simply provide a reachable webhook URL where you want to receive the post request and enjoy the benefits of real-time event notifications. Stay connected and in control with SmartMails’ powerful webhooks feature.</p>
                <ul className="checklist1 text-white text-left pl-0">
                  <li><div className="d-flex mb-3"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z" fill="#FFF"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z" fill="#FFF"></path></svg></span><span className="align-self-center">Receive real-time data updates when relevant events occur</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z" fill="#FFF"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z" fill="#FFF"></path></svg></span><span className="align-self-center">Utilize the received information to review and refine your strategy, if necessary</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z" fill="#FFF"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z" fill="#FFF"></path></svg></span><span className="align-self-center">Instantly identify if there are any failures in data transmission</span></div></li>
                </ul>
              </div>
              <div className="col-md-6 align-self-center">
                <img
                  src={newsletter_amico} alt="bonus"
                  className="img-fluid d-block mx-auto mb-5 max-w550"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="row justify-content-center ">

              <div className="col-md-6 align-self-center bonus-text mt-3 order-md-2">
                <h1 className="lh-4 fw900 text-white ff2"><span className="text-blue fw500">Read</span> This Example</h1>
                <p className="lh-4 fw400 text-off-white margint10 fs-20">Twitter provides an account activity webhook feature that instantly notifies users about messages, replies likes, and more. This allows businesses to respond promptly to queries and engage with their audience on time. Similarly, SmartMails offers real-time information through notifications, enabling you to stay responsive and act promptly.</p>
              </div>
              <div className="col-md-6 align-self-center mt-3 order-md-1">
                <img
                  src={emails_cuate_1} alt="bonus"
                  className="img-fluid d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from "react";
import uncheckIcon34 from "./uncheckIcon.png";
import checkIcon34 from "./checkIcon.png";
import arrow from "./arrow.png";
import paytm from "./paytm.png"

import "./section34.css";
export default function Section34() {
  return (
    <div className="one-section one-section-34 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h1 className="lg42 md36 sm28 xs24 fw900 lh-4 ff2">Take Control of Your Destiny and Shape Your Future</h1> </div>
        </div>
        <div className="col-md-10 mx-auto">
          <div className="row justify-content-around">
            <div className="col-lg-6 col-md-6 without-list-box mt3">
              <div className="col-12 without-list">
                <h3 className="fw400 lh-4 text-white">
                  <span className="without-box"> <img src={uncheckIcon34} className="img-fluid center-block uncheckIcon" alt="uncheck" />Failing Side</span>
                </h3>

                <ul className="without-checklist fw400 lh-4 ps-0">
                  <li>Embrace traditional methods that yield limited results.</li>
                  <li>Waste money on aimless social media and Google campaigns.</li>
                  <li>Exhaust yourself chasing after customers without a strategic approach.</li>
                  <li>Persist in paying advertising agencies with your hard-earned money.</li>
                  <li>Stay trapped in a cycle of failure.</li>
                  <li>Remain out of the competition and miss out on growth opportunities.</li>

                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 with-list-box mt3">
              <div className="with-list">
                <h3 className="fw400 lh-4 text-white text-right">
                <span className="with-box">Stand Victorious<img src={checkIcon34} className="img-fluid center-block checkIcon" alt="check" /></span>
                </h3>
                <ul className="with-checklist fw400 lh-4 ps-0">
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Harness the power of SmartMails jaw-dropping features.</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Save your hard-earned dollars by eliminating the need for hiring professionals.</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Expand your lead database and boost conversion rates.</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Choose from a wide selection of professionally designed templates.</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Effectively manage your contact list and segment based on user preferences.</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Embrace the new world of wonders by leaving behind outdated trends.</span></div></li>
                  <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Nurture your leads and experience increased ROI.</span></div></li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 text-center mt-5">
              <h4 className="lh-4"> <span className="title3-out"><span className="title3 ff2 fw900"><span>Stand Victorious with the Help of SmartMails</span> </span></span> </h4>
            </div>
            <div className=" padtop120">
              <div className="col-md-12 section34-box max-w950 m-auto">
                <div className="col-md-12 section34-box-inn">
                  <div className="row justify-content-around m-0">
                    <h3 className="fw500 mt-2 text-center ff2">Start Using <span className="fw900 text-dark-blue">SmartMails</span></h3>
                    <div className="col-md-12 text-center mt-4">
                      <div className="mybutton">
                        <a href="#pricing" className="text-center fw400 text-uppercase  myimg-width"> <span className="mybutton-btn">
                          Get Started Now
                          <img
                            src={arrow} alt="arrow"
                            className="img-fluid center-block btn-arrow"
                          />
                        </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <img
                        src={paytm} alt="payment"
                        className="img-fluid mx-auto d-block myimg-width"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

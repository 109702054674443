import React from 'react'
import Get_in_touch from './images/best_performing.jpg';
import rafiki from './images/design_newsletters.png';
import './section5.css';

export default function Section5() {
  return (
    <div className="one-section one-section-5 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center mb-5">
              <h1 className='max-w600 m-auto fw900 ff2'>Kick-start Your Newsletter Journey with <span className='headingbox'>SmartMails</span></h1>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 mt-5">
                <div className='max-w520 m-auto text-left'>
                  <div className="row align-items-center">
                    <div className="d-flex">
                      <h2 className='align-self-center fs-22 ff2 fw900'>Engage Your Readers with <br />
                        <span className="fw900 text-dark-blue fs-32"> High-Performing Newsletters</span>
                      </h2>
                    </div>
                  </div>
                  <p className="lh-4 mb-4 mt-3 fs-20">Define the objectives of your newsletters and leverage SmartMails to send them at your convenience. Whether your goals involve informing, inspiring, entertaining, educating, or any other purpose, we empower you to achieve them with grace. Connect with your audience and deliver timely, engaging, and captivating content that is both relevant and interesting.</p>
                </div>
                <div className="max-w520 m-auto">
                  <div className='row'>
                    <div className="col-md-12 align-self-center">
                      <div className="fw400 text-black">
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className="list-paddingx"><b>Mail your newsletter</b> effortlessly through SmartMails.</p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className="list-paddingx">Set the <b>desired frequency</b> for sending.</p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className="list-paddingx">Identify and define your <b>target audience</b>.</p>
                        </div>
                        <div className="d-flex">
                          <div className="check-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg>
                          </div>
                          <p className="list-paddingx">Measure your success through <b>detailed statistics</b>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={Get_in_touch} alt="Get-in-touch"
                  className="img-fluid d-block mx-auto max-w550"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row align-items-center">
              <div className="col-md-6 order-md-2">
                <div className='max-w520 my-mt-5-max767'>
                  <div className="row align-items-center">
                    <div className="d-flex">
                      <h2 className='fs-22'>
                        <span className="fw900 text-dark-blue fs-32 ff2">Tailor Your Newsletters to Meet</span><br />the Preferences of Your Readers
                      </h2>
                    </div>
                  </div>
                  <p className="lh-4 mt-3 fs-20">Once you have established your goals, it's crucial to craft a well-timed, visually appealing, and responsive email newsletter. Ensuring compatibility across mobile phones, desktops, and other devices is vital to avoid losing out on engagement. With SmartMails, you can create your newsletter using either the HTML Editor or the Drag & Drop Builder. Additionally, take advantage of Split Testing to determine the most effective variant.</p>
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <img
                  src={rafiki} alt="rafiki"
                  className="img-fluid d-block mx-auto max-w550"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import bg22 from './images/bg22.png';
import emailcap from "./images/Emailcapture-rafiki1.png";
import './section22.css';

export default function Section22() {
  return (
    <div className="one-section one-section-22 pb-5" style={{ backgroundImage: `url(${bg22})` }}>
      <div className="container">
        <div className="col-md-12 mx-auto">
          <div className="row align-center">
            <div className="col-md-6 col-sm-12 align-self-center">
              <div className=' text-left'>
                <h1 className="fw600 text-white pt-3 ff2"><span className='heading fw900'>Increased Engagements</span>Result in Higher Sales</h1>
                <p className="fw400 text-white">
                SmartMails offers limitless possibilities to harness the power of data-driven marketing automation. By sending targeted emails to specific segments, you can nurture more leads. Unlike other marketing channels like print, electronic, and social media, SmartMails enables you to deliver relevant information directly to your desired audience. With SmartMails, you have the freedom to send the right information to the right people of your choice.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12 align-self-center">
              <img src={emailcap} className="img-fluid d-block max-w500 mx-auto " alt='SmartMails-img' />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
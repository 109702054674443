import React from 'react'
import arrow from "./images/arrow.png";
import paytm from "./images/paytm.png";
import bg19 from './images/bg19.jpg';
import './section19.css';

export default function Section19() {
  return (
    <div className="one-section one-section-19" style={{backgroundImage: `url(${bg19})`}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 section19-box max-w950 m-auto">
            <div className="col-md-12 section19-box-inn">
              <div className="row justify-content-around m-0">
                <div className="col-md-12 text-center mt-2 mb-2">
                  <h1 className="fw900 ff2 lh-4 text-dark-blue">Start Winning from Today!</h1>
                </div>
                <div className="col-md-12 text-center mt-4">
                  <div className="mybutton">
                    <a href="#pricing" className="text-center fw400 text-uppercase"> <span className="mybutton-btn">
                      Get Started Now!
                      <img
                          src={arrow} alt="arrow"
                          className="img-fluid center-block btn-arrow"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <img
                      src={paytm} alt="payment"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from 'react'
import arrow from "./images/arrow.png";
import paytm from "./images/paytm.png";
import bg12 from './images/bg12.jpg';
import './section12.css';

export default function Section12() {
  return (
    <div className="one-section one-section-12" style={{backgroundImage: `url(${bg12})`}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 section12-box max-w950 m-auto mb-5">
            <div className="col-md-12 section12-box-inn">
              <div className="row justify-content-around m-0">
                <h3 className="fw700 mt-2 text-dark-blue text-center">
                <em><span className="fw800 text-dark-blue ff2">Take Your <span className='fw900'>First Step</span> Towards Success</span></em></h3>
                <div className="col-md-12 text-center mt-4">
                  <div className="mybutton">
                    <a href="#pricing" className="text-center fw400 text-uppercase"> <span className="mybutton-btn">
                      Get Started Now
                      <img
                          src={arrow} alt="arrow"
                          className="img-fluid center-block btn-arrow"
                        />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <img
                      src={paytm} alt="payment"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
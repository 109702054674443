import React from "react";
import Logo from "./logo.png";
import "./header.css";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark" id="header-nav">
      <div className="container-xxl p-small-0">
        <a className="navbar-brand fw-bold" href="https://smartmails.io/">
          <img
            src={Logo}
            className="header-logo"
            alt="Smartmails Header Logo"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon1"></span>
          <span className="navbar-toggler-icon2"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="nav-link"
                aria-current="page"
                href="https://smartmails.io/"
              >
                <span className="nav-text">Home</span>
                <span className="menu-background-line"></span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#pricing">
                <span className="nav-text">Pricing</span>
                <span className="menu-background-line"></span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://smartmails.io/features/"
              >
                <span className="nav-text">Features</span>
                <span className="menu-background-line"></span>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="nav-text">About Us</span>
                <span className="menu-background-line"></span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a
                    className="dropdown-item"
                    href="https://smartmails.io/about/#helping"
                  >
                    Helping Hand
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://smartmails.io/about/#what"
                  >
                    What We Do
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="https://smartmails.io/about/#faq"
                  >
                    FAQs
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://smartmails.io/blog/">
                <span className="nav-text">Blog</span>
                <span className="menu-background-line"></span>
              </a>
            </li>
            <li className="nav-item d-small">
              <a className="nav-link" href="https://smartmails.io/contact/">
                <span className="nav-text">Contact us</span>
                <span className="menu-background-line"></span>
              </a>
            </li>
          </ul>
          <div className="navbar-nav sm-icons">
            <a
              className="nav-link hello-call"
              href="https://smartmails.io/contact/"
            >
              <div className="hello-block">
                <div className="hello-icon">
                  <svg
                    fill="#FFFFFF"
                    width="18"
                    height="18"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 10.375c0 0.938 0.344 1.969 0.781 3.063s1 2.125 1.438 2.906c1.188 2.063 2.719 4.094 4.469 5.781s3.813 3.094 6.125 3.938c1.344 0.531 2.688 1.125 4.188 1.125 0.75 0 1.813-0.281 2.781-0.688 0.938-0.406 1.781-1.031 2.094-1.781 0.125-0.281 0.281-0.656 0.375-1.094 0.094-0.406 0.156-0.813 0.156-1.094 0-0.156 0-0.313-0.031-0.344-0.094-0.188-0.313-0.344-0.563-0.5-0.563-0.281-0.656-0.375-1.5-0.875-0.875-0.5-1.781-1.063-2.563-1.469-0.375-0.219-0.625-0.313-0.719-0.313-0.5 0-1.125 0.688-1.656 1.438-0.563 0.75-1.188 1.438-1.625 1.438-0.219 0-0.438-0.094-0.688-0.25s-0.5-0.281-0.656-0.375c-2.75-1.563-4.594-3.406-6.156-6.125-0.188-0.313-0.625-0.969-0.625-1.313 0-0.406 0.563-0.875 1.125-1.375 0.531-0.469 1.094-1.031 1.094-1.719 0-0.094-0.063-0.375-0.188-0.781-0.281-0.813-0.656-1.75-0.969-2.656-0.156-0.438-0.281-0.75-0.313-0.906-0.063-0.094-0.094-0.219-0.125-0.375s-0.094-0.281-0.125-0.406c-0.094-0.281-0.25-0.5-0.406-0.625-0.156-0.063-0.531-0.156-0.906-0.188-0.375 0-0.813-0.031-1-0.031-0.094 0-0.219 0-0.344 0.031h-0.406c-1 0.438-1.719 1.313-2.25 2.344-0.5 1.031-0.813 2.188-0.813 3.219z"></path>
                  </svg>
                </div>
                <div className="hello-text">
                  <span className="hello-content">Contact us today</span>
                  <span className="hello-content">Contact us today</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;

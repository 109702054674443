import React from "react";
import bg26 from './images/bg26.png';
import collect from './images/collect_data.png';
import mobile from './images/mobile_friendly.png';
import bounce from './images/bounce_rate.png';
import social_media from './images/social_media.png';
import investment from './images/return_investment.png';
import measure_campaign from './images/measure_campaign.png';
import landing_page from './images/landing_pages.png';
import distraction_free from './images/distraction_free.png';
import paid_advertising from './images/paid_advertising.png';
import based_interest from './images/based_interest.png';
import launch_campaigns from './images/launch_campaigns.png';
import conversion_rates from './images/conversion_rates.png';
import "./section26.css";
export default function Section26() {
  return (
    <div className="one-section one-section-26" style={{ backgroundImage: `url(${bg26})` }}>
      <div className="container">
      <div className="col-md-10 text-center mx-auto">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h3 className="lh-4 text-black box mb-5 ff2">Discover the endless possibilities for your business by using the dedicated landing page feature offered by SmartMails. Take your sales to new heights and explore a world of unlimited opportunities.</h3>
          </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 section26-box-out ">
              <div className="col-md-12 text-center section26-box bg-pink-light">
                <img
                  src={landing_page} alt="landing_page"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Landing pages are designed to capture visitor information.</p>		
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-yellow-light">
                <img
                  src={conversion_rates} alt="conversion_rates"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Focus on a specific goal, leading to better conversion rates.</p>			
                  </div>	
                </div>	
              </div>	
            </div>		
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-purple-light">
                <img
                  src={distraction_free} alt="distraction_free"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Provide a distraction-free experience for visitors.</p>	
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-green-light">
                <img
                  src={collect} alt="distractions"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Allow you to collect valuable data on your visitors.</p>		
                  </div>	
                </div>	
              </div>	
            </div>	
            {/* <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box">
                <img
                  src={optimized} alt="optimized"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Landing pages can be easily tested and optimized for different elements.</p>		
                  </div>	
                </div>	
              </div>	
            </div>	 */}
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-brown-light">
                <img
                  src={launch_campaigns} alt="launch_campaigns"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Quickly created and deployed, launch campaigns on time.</p>			
                  </div>	
                </div>	
              </div>	
            </div>	
            {/* <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box">
                <img
                  src={action} alt="action"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Provide a focused and prominent call to action.</p>				
                  </div>	
                </div>	
              </div>	
            </div>	
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box">
                <img
                  src={specific_search} alt="specific_search"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Optimized for specific search intent, boosting your organic search rankings.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box">
                <img
                  src={brands_credibility} alt="brands_credibility"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Relevant content can enhance your brand's credibility and trustworthiness.</p>			
                  </div>	
                </div>	
              </div>	
            </div> */}
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-darkgrey-light">
                <img
                  src={paid_advertising} alt="paid_advertising"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Landing pages can be directly linked to your paid advertising campaigns.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            {/* <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box">
                <img
                  src={targeted_promotions} alt="targeted_promotions"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Enable you to create targeted promotions, discounts, or offers.</p>			
                  </div>	
                </div>	
              </div>	
            </div> */}
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-yellow-light">
                <img
                  src={measure_campaign} alt="measure_campaign"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Allow you to measure campaign performance, enabling timely decision-making.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            {/* <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box">
                <img
                  src={complete_control} alt="complete_control"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">You have complete control over the content, messaging, and design.</p>			
                  </div>	
                </div>	
              </div>	
            </div> */}
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-pink-light">
                <img
                  src={based_interest} alt="based_interest"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Help you segment your leads based on their interests.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-green-light">
                <img
                  src={investment} alt="investment"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Maximize your return on investment by focusing on specific campaigns.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-purple-light">
                <img
                  src={social_media} alt="social_media"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Share on social media platforms, driving engagement and traffic back to your website.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-darkgrey-light">
                <img
                  src={mobile} alt="mobile"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Designed to be mobile-friendly, catering to the growing number of mobile users.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
            <div className="col-lg-4 col-md-6 section26-box-out">
              <div className="col-md-12 text-center section26-box bg-brown-light">
                <img
                  src={bounce} alt="bounce"
                  className="img-fluid mx-auto d-block" 
                /> 
                <div className="col-12 list-text-top d-table">
                  <div className="card card-body d-table-cell align-middle">
                    <p className="lh-4 feature-text">Landing pages can reduce bounce rates and increase visitor engagement.</p>			
                  </div>	
                </div>	
              </div>	
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

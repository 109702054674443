import React from "react";
import sentmsg from "./images/take-elevate.png";
import bg11 from "./images/bg11.png";
import "./section11.css";
export default function Section11() {
  return (
    <div className="one-section one-section-11" style={{backgroundImage: `url(${bg11})`}}>
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="card">
            <div className="card-body p-5">
              <div className="row justify-content-center">
                <div className="col-md-6 align-self-center">
                  <img src={sentmsg} className="img-fluid mx-auto d-block max-w500 sentmsg" alt="wondering" />
                </div>
                <div className="col-md-6 col-sm-12 align-self-center">
                  <div className="m-auto max-w550">
                  <h1 className="fw700 fs-h1-42 ff2">Elevate Your <span className="text-dark-blue fw900">Marketing Experience</span></h1>
                <p className="lh-4 gray margint10">Don't wait for tomorrow to take charge of your marketplace. Seize the opportunity today with SmartMails and experience the next level of success. Whether you're an online business, a social media agency, a designer, a content marketer, or any other type of business, SmartMails welcomes you to elevate your operations to new heights. With its comprehensive all-in-one solution, we empower you to lead the way in your industry and unlock advanced possibilities for your business.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import star from './images/star.png';
import anthony from './images/anthony.jpg';
import firas from './images/firas.jpg';
import arif from './images/arif.jpg';
import bg13 from './images/bg13.png';
import './section13.css';

export default function Section13() {
  return (


    <div className="one-section one-section-13" style={{ backgroundImage: `url(${bg13})` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">

            <h1 className="fw900 ff2 lh-4 text-white fs-h1-42">What do Market Players Say About Us?</h1>
          </div>
        </div>
        <div className="row justify-content-center mt2">
          <div className="col-lg-4 col-md-4 testimonial-box-out">
            <div className="col-12 testimonial-box">
              <div className="row m-0">
                <div className="col-md-2 p-0"> <img src={firas} className="img-fluid mx-auto d-block person1" alt="person" /> </div>
                <div className="col-md-9">
                  <p className="mb-0 fw900 ff2 lh-4">George Carlino</p> <img src={star} className="img-fluid center-block star" alt="star" />
                </div>
              </div>
              <p className=" fw400 lh-4 gray2 mt-4 p-fs">“SmartMails has revolutionized our marketing efforts. Its user-friendly interface and seamless functionality have transformed our marketing processes. Automated follow-up sequences, it has modernized our email workflow. I can confidently say that SmartMails is a must-have tool for anyone seeking to enhance communication and increase efficiency. I highly recommend it.”</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 testimonial-box-out">
            <div className="col-12 testimonial-box">
              <div className="row m-0">
                <div className="col-md-2 p-0"> <img src={arif} className="img-fluid mx-auto d-block person2" alt="person" /> </div>
                <div className="col-md-9">
                  <p className="mb-0 fw900 ff2 lh-4">Amber Miron</p> <img src={star} className="img-fluid center-block star" alt="star" />
                </div>
              </div>
              <p className="fw400 lh-4 gray2 mt-4 p-fs">“SmartMails stands out as an exceptional email marketing platform, backed by outstanding customer support. Their responsive and knowledgeable team consistently goes the extra mile to address any concerns I may have. With their unwavering commitment to ensuring a seamless experience, SmartMails surpasses other providers in their dedication to customer success.”</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 testimonial-box-out">
            <div className="col-12 testimonial-box">
              <div className="row m-0">
                <div className="col-md-2 p-0"> <img src={anthony} className="img-fluid mx-auto d-block person3" alt="person" /> </div>
                <div className="col-md-9">
                  <p className="mb-0 fw900 ff2 lh-4">Connor Winn</p> <img src={star} className="img-fluid center-block star" alt="star" />
                </div>
              </div>
              <p className="fw400 lh-4 gray2 mt-4 p-fs">“SmartMails provides an extensive range of features. The powerful segmentation capabilities allowed us to target specific customer groups, resulting in higher engagement and conversion rates. The A/B testing feature has been instrumental in optimizing my email content, enabling us to achieve the best possible results. I highly recommend it to any business or marketer serious about their email campaigns.”</p>
            </div>
          </div>

        </div>
      </div>
    </div>


  );
}
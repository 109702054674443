import React from "react";
import connection2 from "./images/connection2.png";
import "./section17.css";
export default function Section17() {
    return (
    <>
        <div className="one-section one-section-17">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 align-self-center blk-box">
                        <h1 className="fw900 lh-4 text-white mb-2 ff2">Discover How Easy It Is to Get Started with SmartMails!</h1>
                        <h3 className="fw900 lh-4 inner-box1 ff2">Simply follow these easy steps and make a powerful impact in your market!</h3>
                    </div>
                    <div className="col-md-12 text-center">
                        <img
                            src={connection2} alt="connection"
                            className="img-fluid connection2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
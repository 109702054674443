import React from "react";
import bg31 from "./images/bg31.png";
import form from "./images/form-body.png";

import "./section31.css";
export default function Section31() {
  return (
    <div className="one-section one-section-31 section200 my-minus-mt2" style={{ backgroundImage: `url(${bg31})` }}>
      <div className="container">
        <div className="row justify-content-center"><div className="col-md-12 text-center mt2">
          <h1 className="fw900 text-black max-w600 mx-auto ff2">Choose Your Preferred Web Forms on SmartMails</h1>
          <p className="mx-auto fs-20 fw400 gray lh-4 margint10 max-w950 py-5">SmartMails offers a wide range of customizable web form templates to suit your specific needs. From blank templates to simple subscription forms, contact forms, subscribe forms, contact forms with addresses, complaint monitor forms, campaign monitor forms with backgrounds, and more, you have the flexibility to create web forms that align with your requirements. Tailor your forms to perfection with SmartMails’ extensive selection of templates.</p>
        </div></div>
        <div className="row justify-content-center mt5">
          <div className="col-lg-6 col-md-6 align-self-center"> <img src={form} className="img-fluid mx-auto d-block wasting max-w500" alt=" form" /> </div>
          <div className="col-lg-6 col-md-6 align-self-center">
            <ul className="checklist1 text-left">
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Define your preferred web form in SmartMails.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Obtain HTML code.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Embed the copied code into your website's HTML viewer window.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Capture leads and automate lead-nurturing activities using SmartMails.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Save money and manual effort.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Enhance your communication workflow.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Ensure GDPR-related consent.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Start engaging with interested users.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Customize the form to meet your specific needs.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Streamline data handling processes.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">User-friendly and convenient to use.</span></div></li>
              <li><div className="d-flex mb-2"><span className="check-icon d-flex"><svg width="24" height="24" viewBox="0 0 24 24" className="check-icon-inner"><path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path><path d="M9.999 13.587L7.7 11.292 6.288 12.708 10.001 16.413 16.707 9.707 15.293 8.293z"></path></svg></span><span className="align-self-center">Foster the growth of a healthy contact list.</span></div></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
